import React from 'react';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

export default function Empty() {
  return (
    <Card style={{ width: '100%' }}>
      <Card.Header as={'h5'}>Welcome to Mímir!</Card.Header>
      <Card.Body>
        <Card.Text>
          Mímir is a visual platform to explore the{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.bar.admin.ch/bar/en/home.html"
          >
            Swiss Federal Archives
          </a>
          . It aggregates the results according to the{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.recherche.bar.admin.ch/recherche/#/en/search/archive-plan"
          >
            archive plan
          </a>{' '}
          and presents them with{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://pubsys.mmsp-kn.de/pubsys/publishedFiles/BaDe05b.pdf"
          >
            Voronoi treemaps
          </a>
          .
        </Card.Text>
      </Card.Body>
      <Card.Header as={'h6'}>Instructions</Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item>
          Enter your text query in the <b>search bar</b>
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Hover</b> on a node to get basic info
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Press "i"</b> to discover additional information
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Click</b> on a node to expand its direct children
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Alt/Option+Click</b> on a node to expand all its children
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Shift+Click</b> on a node to discover more on the SFA
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Right-click</b> on a node to collapse its children
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
}
