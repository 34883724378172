import React from 'react';
import { selectQuery, selectResult } from '../navbar/querySlice';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';

export default function QueryResult() {
  const nResults = useSelector(selectResult)?.nResults;
  const lastQuery = useSelector(selectQuery);
  const isLastQueryAdvanced = lastQuery?.advanced || false;
  const lastSimpleQueryText = lastQuery?.fields?.find((f) => f.key === 'allData')?.value;
  const lastAdvancedQuery = lastQuery?.fields
    ?.filter((f) => f.value)
    ?.map((f) => `${f.key}=${f.value}`)
    ?.join(', ');

  const text = (
    <span className="text-info">
      {isLastQueryAdvanced ? lastAdvancedQuery : lastSimpleQueryText}
    </span>
  );

  return (
    <Container>
      <div className="pt-sm-2 pb-sm-2 text-sm-right">
        {nResults} results for {text}
      </div>
    </Container>
  );
}
