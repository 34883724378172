import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import logo from '../../logo.svg';
import Query from './Query';
import { useDispatch } from 'react-redux';
import { openAboutModal } from '../about/aboutSlice';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export default function CNavbar() {
  const dispatch = useDispatch();

  return (
    <Navbar fixed="top" bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home" onClick={() => dispatch(openAboutModal())}>
          <img alt="" src={logo} width="30" height="30" className="d-inline-block align-top" />{' '}
          &nbsp; Mímir // A Visual Browser for the Federal Archives
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">{/*<Nav.Link href="#home">Home</Nav.Link>*/}</Nav>
          <Query />
        </Navbar.Collapse>
        <Button className="ml-sm-2" variant="link" onClick={() => dispatch(openAboutModal())}>
          About
        </Button>
      </Container>
    </Navbar>
  );
}
