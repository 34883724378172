import React from 'react';
import { RotateLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { selectLoading } from '../navbar/querySlice';
import config from '../../config';

export default function Spinner() {
  const isLoading = useSelector(selectLoading);
  return (
    <div className="RotateLoader">
      <RotateLoader size={100} margin={150} color={config.UI.SPINNER_COLOR} loading={isLoading} />
    </div>
  );
}
