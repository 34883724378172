import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectShowModal } from './searchSlice';
import { performQuery } from '../navbar/querySlice';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function SearchModal() {
  // Search fields
  const [allData, setAllData] = useState('');
  const [metadata, setMetadata] = useState('');
  const [primaryData, setPrimaryData] = useState('');
  const [title, setTitle] = useState('');
  const [period, setPeriod] = useState('');
  const [withinInfo, setWithinInfo] = useState('');
  const [refCode, setRefCode] = useState('');
  const [form, setForm] = useState('');
  const [country, setCountry] = useState('');

  // Error
  const [formError, setFormError] = useState('');

  // Dispatch and selectors used in this component
  const dispatch = useDispatch();
  const showModal = useSelector(selectShowModal);

  // Function to close modal
  const handleClose = () => dispatch(closeModal());

  const searchFields = [
    {
      label: 'All',
      key: 'allData',
      description: 'Search everywhere',
      get: () => allData,
      set: setAllData,
    },
    {
      label: 'Metadata',
      key: 'allMetaData',
      description: 'Searches in all metadata fields.',
      get: () => metadata,
      set: setMetadata,
    },
    {
      label: 'Content',
      key: 'allPrimaryData',
      description: 'Searches in the textual data of referenced digital files, e.g. primaryData.',
      get: () => primaryData,
      set: setPrimaryData,
    },
    {
      label: 'Title',
      key: 'title',
      description: 'Searches the title field.',
      get: () => title,
      set: setTitle,
    },
    {
      label: 'Year range',
      key: 'creationPeriod',
      description:
        'Searches a data range. The record must have been created in this period. Use 1950-1960 or 24.12.1950-1960 or simply 1914.',
      get: () => period,
      set: setPeriod,
    },
    {
      label: 'Description',
      key: 'withinInfo',
      description: 'Searches the Within Info field. (Darin-Vermerk)',
      get: () => withinInfo,
      set: setWithinInfo,
    },
    {
      label: 'Reference code',
      key: 'referenceCode',
      description: 'Searches for the reference code. (Signatur)',
      get: () => refCode,
      set: setRefCode,
    },
    {
      label: 'Form',
      key: 'customFields.form',
      description:
        'Searches for the identification of special types. (medium) and forms of organisation of documents.',
      get: () => form,
      set: setForm,
    },
    {
      label: 'Country',
      key: 'customFields.land',
      description: 'Searches for the country.',
      get: () => country,
      set: setCountry,
    },
  ];

  // Setter for any field
  const setField = (field) => (e) => {
    setFormError('');
    field.set(e.target.value);
  };

  // Check whether all fields are empty
  const allFieldsEmpty = (fields) => fields.every((f) => f.get().trim() === '');

  // Event handler for submit button
  const submit = (e) => {
    e.preventDefault();
    if (allFieldsEmpty(searchFields)) {
      setFormError('All fields are empty!');
      return;
    }
    const query = {
      fields: searchFields.map((field) => {
        return {
          key: field.key,
          value: field.get(),
        };
      }),
    };
    dispatch(performQuery(query)).then(() => searchFields.forEach((f) => f.set('')));
    handleClose();
  };

  return (
    <Form inline>
      <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Advanced Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {searchFields.map((f) => (
            <Form.Group as={Row} key={f.key}>
              <Form.Label column sm="3">
                {f.label}
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder={f.description}
                  value={f.get()}
                  onChange={setField(f)}
                />
              </Col>
            </Form.Group>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <span className="text-danger">{formError}</span>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submit}>
            Search
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}
