import React from 'react';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import { selectHoverSite } from '../voronoiSlice';
import { selectResultsById } from '../../navbar/querySlice';
import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';

export default function Tooltip() {
  const hoverSiteId = useSelector(selectHoverSite);
  const resultsById = useSelector(selectResultsById) || {};
  const hoverNode = resultsById[hoverSiteId] || null;

  return (
    <MouseTooltip visible={hoverNode !== null} offsetX={15} offsetY={10} style={{ zIndex: 9999 }}>
      <div>
        {hoverNode && (
          <Card style={{ width: '300px' }}>
            <Card.Body>
              <Card.Title as={'h6'} className={'text-truncate'}>
                {hoverNode.title}
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted text-truncate">
                {hoverNode.level}
              </Card.Subtitle>
              <Card.Subtitle className="text-muted text-truncate">
                {hoverNode.dateRangeText}
              </Card.Subtitle>
            </Card.Body>
            <Card.Footer className="mt-2 text-muted">
              <span className="mr-sm-2" role="img" aria-label="Lamp emoji">
                💡
              </span>
              <span>Press "i" to learn more</span>
            </Card.Footer>
          </Card>
        )}
      </div>
    </MouseTooltip>
  );
}
