import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { performQuery, selectError } from './querySlice';
import { openModal } from '../search/searchSlice';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function Query() {
  // The component state is just the query text (for now)
  const [text, setText] = useState('');

  // Dispatch and selectors used in this component
  const dispatch = useDispatch();
  const error = useSelector(selectError);

  // Event handlers for input fields
  const onChangeText = (e) => setText(e.target.value);

  // Event handler for submit button
  const submit = (e) => {
    e.preventDefault();
    if (!text.trim()) {
      return;
    }
    dispatch(performQuery({ text })).then(() => setText(''));
  };

  return (
    <Form inline>
      <Form.Control
        type="text"
        placeholder="Enter your query"
        className="mr-sm-2"
        style={{ width: '300px' }}
        value={text}
        onChange={onChangeText}
      />
      <span className="text-danger position-absolute" style={{ bottom: '-12px' }}>
        {error}
      </span>
      <Button variant="outline-success" type="submit" onClick={submit}>
        <span role="img" aria-label="search icon">
          🔎
        </span>
      </Button>
      <Button className="ml-sm-2" variant="outline-info" onClick={() => dispatch(openModal())}>
        Advanced Search
      </Button>
    </Form>
  );
}
