import { createSlice } from '@reduxjs/toolkit';
import config from '../../config';

export const yearRangeSlice = createSlice({
  name: 'yearRange',
  initialState: {
    range: {
      start: config.YEARS.MIN,
      end: config.YEARS.MAX,
    },
  },
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setRange: (state, action) => {
      state.range = action.payload;
    },
    resetRange: (state) => {
      state.range = {
        start: config.YEARS.MIN,
        end: config.YEARS.MAX,
      };
    },
  },
});

export const { setRange, resetRange } = yearRangeSlice.actions;

export const selectRange = (state) => state.yearRange.range;

export default yearRangeSlice.reducer;
