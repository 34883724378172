import axios from 'axios';
import config from '../config';

export async function sendQuery(query) {
  const url = `${config.MIMIR_API.URL}/query`;
  const fixedQuery = query.text
    ? { fields: [{ key: 'allData', value: query.text }], advanced: false }
    : { ...query, advanced: true };
  const { data } = await axios.post(url, JSON.stringify(fixedQuery), {
    headers: { 'Content-Type': 'application/json' },
  });
  return data;
}

export async function sendGetEntity(entityId) {
  const url = `${config.MIMIR_API.URL}/entity/${entityId}`;
  const { data } = await axios.get(url);
  return data;
}
