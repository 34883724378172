import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRange } from './yearRangeSlice';
import ReactEcharts from 'echarts-for-react';
import { selectNodes, selectQuery, selectResultsById } from '../navbar/querySlice';
import { useDebouncedCallback } from 'use-debounce';
import config from '../../config';
import logger from '../../utils/logger';
import Container from 'react-bootstrap/Container';
import { selectHoverSite } from '../voronoi/voronoiSlice';

export default function YearRange() {
  const dispatch = useDispatch();
  const [debouncedDispatch] = useDebouncedCallback((action) => dispatch(action), 300);

  // Whether the chart is zoomed on a single year or not
  const [zoomed, setZoomed] = useState(false);

  // Data from redux state
  const query = useSelector(selectQuery);
  const nodes = useSelector(selectNodes);
  const resultsById = useSelector(selectResultsById);
  const hoverSiteId = useSelector(selectHoverSite);

  const chartRef = useRef(null);

  // Reset zoom when data changes completely
  useEffect(() => {
    logger.debug('YearRange: resetting chart zoom');
    const chart = chartRef.current.getEchartsInstance();
    chart.dispatchAction({ type: 'dataZoom', start: 0, end: 100 });
  }, [query]);

  // Create data for chart and store it in redux
  useEffect(() => {
    logger.debug('YearRange: creating data for chart');
    const yearCounts = (nodes || [])
      .filter((n) => n.leaf)
      .map((n) => resultsById[n.name])
      .filter((n) => n)
      .flatMap((n) => n.years)
      .reduce((counts, y) => {
        if (counts[y]) counts[y] = counts[y] + 1;
        else counts[y] = 1;
        return counts;
      }, {});
    const yearList = Object.keys(yearCounts).map((y) => ({ year: y, count: yearCounts[y] }));

    // Years of node that is being hovered on Voronoi diagram
    const voronoiHoverNode = resultsById[hoverSiteId] || null;
    const getAllChildren = (nodeId) => {
      if (nodeId === null) return [];
      const children = nodes.filter((node) => node.parent === nodeId);
      return [...children, ...children.flatMap((c) => getAllChildren(c.name))];
    };
    const voronoiHoverNodeYears = new Set([
      ...(voronoiHoverNode?.years || []),
      ...getAllChildren(hoverSiteId)
        .map((c) => resultsById[c.name])
        .flatMap((n) => n?.years || []),
    ]);

    // Highlight bars in the bar chart if there is no node being hovered, or if the year corresponding
    // to the bar is contained in the years of the note that is being hovered.
    const highlight = (yearString) => {
      return !hoverSiteId || voronoiHoverNodeYears.has(parseInt(yearString, 10));
    };

    // Create xAxis (categorical years) and yAxis (year counts, with highlight depending on hovered
    // voronoi node)
    const xAxis = yearList.map((y) => parseInt(y.year, 10));
    const yAxis = yearList.map((y) => ({
      value: y.count,
      itemStyle: { color: highlight(y.year) ? 'rgba(40, 55, 131, 1)' : 'rgba(40, 55, 131, 0.1)' },
    }));

    // Set data in chart
    const chart = chartRef.current.getEchartsInstance();
    chart.setOption({
      xAxis: {
        type: 'category',
        name: 'year',
        data: xAxis,
        silent: false,
        splitLine: {
          show: false,
        },
        splitArea: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        name: 'count',
        show: false,
        splitArea: {
          show: false,
        },
      },
      series: [
        {
          type: 'bar',
          data: yAxis,
          large: true,
        },
      ],
    });
  }, [debouncedDispatch, resultsById, nodes, hoverSiteId]);

  // Handler for dataZoom event on chart
  const dataZoomHandler = useCallback(
    (chart) => (e) => {
      const axis = chart.getModel().option.xAxis[0];
      const start = axis.data[axis.rangeStart] || config.YEARS.MIN;
      const end = axis.data[axis.rangeEnd] || config.YEARS.MAX;
      setZoomed(start === end);
      debouncedDispatch(setRange({ start, end }));
    },
    [debouncedDispatch],
  );

  // Handler for mouseover event, sets the year range to the hovered year (if not zoomed on a year)
  const mouseOverHandler = useCallback(
    (e) => {
      if (!zoomed) {
        const y = parseInt(e.name, 10);
        debouncedDispatch(setRange({ start: y, end: y }));
      }
    },
    [debouncedDispatch, zoomed],
  );

  // Handler for mouseout event, resets the year range (if not zoomed on a year)
  const mouseOutHandler = useCallback(
    (chart) => (e) => {
      if (!zoomed) {
        const axis = chart.getModel().option.xAxis[0];
        const start = axis.data[axis.rangeStart] || config.YEARS.MIN;
        const end = axis.data[axis.rangeEnd] || config.YEARS.MAX;
        debouncedDispatch(setRange({ start, end }));
      }
    },
    [debouncedDispatch, zoomed],
  );

  // Handler for click event, zooms on a single year or zooms out to the complete year range,
  // and sets the "zoomed" flag accordingly
  const clickHandler = useCallback(
    (chart) => (e) => {
      logger.debug('Click on chart');
      if (zoomed) {
        logger.debug('\t-> back to overview');
        chart.dispatchAction({ type: 'dataZoom', start: 0, end: 100 });
      } else {
        logger.debug('\t-> zoom on clicked year');
        chart.dispatchAction({ type: 'dataZoom', startValue: e.name, endValue: e.name });
      }
      // Toggle zoomed
      setZoomed(!zoomed);
    },
    [zoomed],
  );

  // Setup chart event handlers
  useEffect(() => {
    logger.debug('YearRange: registering chart handlers');
    const chart = chartRef.current.getEchartsInstance();
    chart.off('datazoom');
    chart.off('click');
    chart.off('mouseover');
    chart.off('mouseout');
    chart.on('datazoom', dataZoomHandler(chart));
    chart.on('click', clickHandler(chart));
    chart.on('mouseover', mouseOverHandler);
    chart.on('mouseout', mouseOutHandler(chart));
  }, [dataZoomHandler, clickHandler, mouseOverHandler, mouseOutHandler]);

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      transitionDuration: 0,
    },
    grid: {
      top: 50,
    },
    dataZoom: [
      {
        type: 'slider',
        height: 4,
        bottom: 20,
        borderColor: 'transparent',
        backgroundColor: '#e2e2e2',
        handleIcon:
          'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7v-1.2h6.6z M13.3,22H6.7v-1.2h6.6z M13.3,19.6H6.7v-1.2h6.6z', // jshint ignore:line
        handleSize: 15,
        handleStyle: {
          shadowBlur: 6,
          shadowOffsetX: 1,
          shadowOffsetY: 2,
          shadowColor: '#aaa',
        },
      },
      {
        type: 'inside',
      },
    ],
    xAxis: {
      type: 'category',
      name: 'year',
      data: [],
      silent: false,
      splitLine: {
        show: false,
      },
      splitArea: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      name: 'count',
      show: false,
      splitArea: {
        show: false,
      },
    },
    series: [
      {
        type: 'bar',
        data: [],
        large: true,
      },
    ],
  };

  return (
    <Container className="pb-sm-1">
      <ReactEcharts ref={chartRef} option={options} style={{ height: '140px', width: '100%' }} />
    </Container>
  );
}
