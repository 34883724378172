import { createSlice } from '@reduxjs/toolkit';
import { sendQuery } from '../../api/mimirAPI';

import stubData from '../../stub/voro-università-della-svizzera-italiana.json';
import { clearEntity } from '../voronoi/voronoiSlice';
import { resetRange } from '../yearrange/yearRangeSlice';

export const querySlice = createSlice({
  name: 'query',
  initialState: {
    result: stubData,
    error: null,
    loading: false,
    noResultModalOpen: false,
  },
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    getQueryResultSuccess: (state, action) => {
      state.result = action.payload;
      state.error = null;
    },
    getQueryResultFailure: (state, action) => {
      state.error = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    openNoResultModal: (state) => {
      state.noResultModalOpen = true;
    },
    closeNoResultModal: (state) => {
      state.noResultModalOpen = false;
    },
  },
});

export const {
  getQueryResultSuccess,
  getQueryResultFailure,
  setLoading,
  openNoResultModal,
  closeNoResultModal,
} = querySlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(performQuery(<query>))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const performQuery = (query) => async (dispatch) => {
  dispatch(setLoading(true));
  return sendQuery(query)
    .then((data) => {
      if (data.nResults === 0) {
        return dispatch(openNoResultModal());
      }
      dispatch(clearEntity());
      dispatch(resetRange());
      return dispatch(getQueryResultSuccess(data));
    })
    .catch((err) => {
      return dispatch(getQueryResultFailure(err.toString()));
    })
    .finally(() => dispatch(setLoading(false)));
};

export const selectQuery = (state) => state.query.result?.query;
export const selectResult = (state) => state.query.result;
export const selectError = (state) => state.query.error;
export const selectResultsById = (state) => state.query.result?.mapById;
export const selectLoading = (state) => state.query.loading;
export const selectNodes = (state) => state.query.result?.nodes;
export const selectNoResultModalOpen = (state) => state.query.noResultModalOpen;

export default querySlice.reducer;
