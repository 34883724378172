import React from 'react';
import Card from 'react-bootstrap/Card';
import Person from './Person';
import people from './people';

export default function About(props) {
  const { message, isMobile } = props;

  const footerStyle = isMobile ? { position: 'fixed', bottom: 0, width: '100%' } : {};

  return (
    <>
      <Card.Body style={{ fontSize: '1.1rem' }}>
        {isMobile && (
          <React.Fragment>
            <Card.Title as={'h3'}>Welcome to Mímir</Card.Title>
            <Card.Text
              as={'h5'}
              className='text-danger'
              style={{ paddingBottom: '1rem', paddingTop: '1rem' }}
            >
              {message}
            </Card.Text>
          </React.Fragment>
        )}
        <Card.Text style={{ paddingBottom: '0rem' }}>
          Mímir is a visual platform to explore the{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.bar.admin.ch/bar/en/home.html'
          >
            Swiss Federal Archives
          </a>
          . It aggregates the results according to the{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.recherche.bar.admin.ch/recherche/#/en/search/archive-plan'
          >
            archive plan
          </a>{' '}
          and presents them with{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://pubsys.mmsp-kn.de/pubsys/publishedFiles/BaDe05b.pdf'
          >
            Voronoi treemaps
          </a>
          .
        </Card.Text>

        <Card.Text style={{ paddingBottom: '0rem' }}>
          Watch the video below to learn more!
        </Card.Text>

        <div className='embed-responsive embed-responsive-16by9'>
          <iframe
            src='https://www.youtube.com/embed/wKFd5wOgjSk'
            allowfullscreen='allowfullscreen'
            mozallowfullscreen='mozallowfullscreen'
            msallowfullscreen='msallowfullscreen'
            oallowfullscreen='oallowfullscreen'
            webkitallowfullscreen='webkitallowfullscreen'
          >
            {' '}
          </iframe>
        </div>

        <Card.Text style={{ paddingTop: '1rem' }}>
          Created by{' '}
          {people.map((p, i) => (
            <span key={i}>
              {i > 0 && ', '}
              <Person info={p} />
            </span>
          ))}
          .
        </Card.Text>
      </Card.Body>
      <Card.Footer style={footerStyle}>
        <a href='https://gitlab.reveal.si.usi.ch/_public/mimir' target='_blank'>
          Developed
        </a>{' '}
        with{' '}
        <span role='img' aria-label='heart'>
          ❤️
        </span>{' '}
        at the{' '}
        <a href='https://si.usi.ch/' target='_blank' rel='noopener noreferrer'>
          Software Institute
        </a>
      </Card.Footer>
    </>
  );
}
