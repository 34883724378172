import React from 'react';
import Card from 'react-bootstrap/Card';
import About from '../about/About';

export default function Mobile() {
  return (
    <Card className="border-bottom-0">
      <About
        isMobile={true}
        message={
          '⛔️ Mímir is not yet optimized for mobile devices. Please come back using your desktop computer!'
        }
      />
    </Card>
  );
}
