import { createSlice } from '@reduxjs/toolkit';

export const aboutSlice = createSlice({
  name: 'about',
  initialState: {
    aboutModalOpen: false,
  },
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    openAboutModal: (state) => {
      state.aboutModalOpen = true;
    },
    closeAboutModal: (state) => {
      state.aboutModalOpen = false;
    },
  },
});

export const { openAboutModal, closeAboutModal } = aboutSlice.actions;

export const selectAboutModalOpen = (state) => state.about.aboutModalOpen;

export default aboutSlice.reducer;
