import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { closeAboutModal, selectAboutModalOpen } from './aboutSlice';
import About from './About';

export default function AboutModal(props) {
  const dispatch = useDispatch();
  const aboutModalOpen = useSelector(selectAboutModalOpen);

  // Function to close modal
  const handleClose = () => dispatch(closeAboutModal());

  return (
    <Modal show={aboutModalOpen} onHide={handleClose} backdrop="static" keyboard={false} size="md">
      <Modal.Header closeButton>
        <Modal.Title>About Mímir</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-sm-0">
        <About isMobile={false} />
      </Modal.Body>
    </Modal>
  );
}
