import { createSlice } from '@reduxjs/toolkit';
import { sendGetEntity } from '../../api/mimirAPI';

const voronoiSlice = createSlice({
  name: 'voronoi',
  initialState: {
    // Id of hovered site
    hoverSite: null,
    // Entity that is selected
    entity: null,
    error: null,
  },
  reducers: {
    setHoverSite: (state, action) => {
      state.hoverSite = action.payload;
    },
    clearHoverSite: (state) => {
      state.hoverSite = null;
    },

    getEntityResultSuccess: (state, action) => {
      state.entity = action.payload;
      state.error = null;
    },
    getEntityResultFailure: (state, action) => {
      state.entity = null;
      state.error = action.payload;
    },
    clearEntity: (state) => {
      state.entity = null;
      state.error = null;
    },
  },
});

// Actions to dispatch
export const {
  setHoverSite,
  clearHoverSite,
  getEntityResultSuccess,
  getEntityResultFailure,
  clearEntity,
} = voronoiSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(performQuery(<query>))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const performGetEntity = (query) => async (dispatch) => {
  try {
    const data = await sendGetEntity(query);
    dispatch(getEntityResultSuccess(data));
  } catch (err) {
    dispatch(getEntityResultFailure(err.toString()));
  }
};

// Getters
export const selectHoverSite = (state) => state.voronoi.hoverSite;
export const selectEntity = (state) => state.voronoi.entity;

// Reducer for store configuration
export default voronoiSlice.reducer;
