import React from 'react';

export default function Person(props) {
  const { name, website } = props.info;

  return (
    <a href={website} target="_blank" rel="noopener noreferrer">
      {name}
    </a>
  );
}
