export default [
  {
    name: 'Aron Fiechter',
    website: 'http://aron.fiechter.gitlab.io',
  },
  {
    name: 'Federico Pfahler',
    website: 'https://www.linkedin.com/in/federico-pfahler-923306132',
  },
  {
    name: 'Davide Paolo Tua',
    website: 'https://www.linkedin.com/in/davide-tua-5956595/',
  },
  {
    name: 'Roberto Minelli',
    website: 'https://robertominelli.com/',
  },
  {
    name: 'Michele Lanza',
    website: 'https://www.inf.usi.ch/faculty/lanza/',
  },
];
