import React from 'react';
import { useSelector } from 'react-redux';

import { selectEntity } from '../voronoiSlice';
import Info from './Info';
import Empty from './Empty';

export default function Entity(props) {
  const entity = useSelector(selectEntity);

  if (entity) {
    return <Info node={entity} />;
  } else {
    return <Empty />;
  }
}
