import React from 'react';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { clearEntity } from '../voronoiSlice';

export default function Info(props) {
  const { node } = props;

  const dispatch = useDispatch();

  const hasImage = node.hasImage;

  return (
    <Card style={{ width: '100%' }}>
      {hasImage && (
        <Card.Img
          variant="top"
          src={`data:image/jpeg;base64,${node.customFields.bildAnsicht.value}`}
        />
      )}
      <Card.Header as={'h5'}>{node.title}</Card.Header>
      <Card.Body style={{ maxHeight: hasImage ? '150px' : '480px', overflowX: 'scroll' }}>
        <Card.Subtitle className="mb-3 text-muted">
          {node.referenceCode} ({node.level})
        </Card.Subtitle>
        {node.creationPeriod?.text && (
          <Card.Subtitle className="mb-2 text-muted">{node.creationPeriod.text}</Card.Subtitle>
        )}
        {node.withinInfo && (
          <Card.Text style={{ whiteSpace: 'pre-wrap' }}>{node.withinInfo}</Card.Text>
        )}
      </Card.Body>
      <ListGroup variant="flush">
        {node.customFields.digitaleVersion &&
          node.customFields.digitaleVersion.map((content) => (
            <ListGroup.Item>
              <Card.Link target="_blank" href={`${content.url}`}>
                {content.text}
              </Card.Link>
            </ListGroup.Item>
          ))}
      </ListGroup>

      <Card.Footer>
        <Button
          style={{ width: '100%' }}
          target="_blank"
          href={`https://www.recherche.bar.admin.ch/recherche/link/en/archive/unit/${node.archiveRecordId}`}
          variant="secondary"
        >
          {' '}
          Open in the Archive
        </Button>
      </Card.Footer>
      <div className="position-absolute w-100 p-sm-2">
        <div
          className="float-right ml-sm-2 text-danger"
          style={{ cursor: 'pointer' }}
          onClick={() => dispatch(clearEntity())}
        >
          <MdClose />
        </div>
      </div>
    </Card>
  );
}
