import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import counterReducer from '../components/counter/counterSlice';
import voronoiReducer from '../components/voronoi/voronoiSlice';
import queryReducer from '../components/navbar/querySlice';
import searchReducer from '../components/search/searchSlice';
import yearRangeReducer from '../components/yearrange/yearRangeSlice';
import aboutReducer from '../components/about/aboutSlice';
import config from '../config';

const middleware = [...getDefaultMiddleware(), ...(config.ENV.IS_PRODUCTION ? [] : [logger])];

export default configureStore({
  reducer: {
    counter: counterReducer,
    voronoi: voronoiReducer,
    query: queryReducer,
    search: searchReducer,
    yearRange: yearRangeReducer,
    about: aboutReducer,
  },
  middleware,
});
