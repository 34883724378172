const ENV = {
  IS_PRODUCTION: process.env.NODE_ENV === 'production',
};

const MIMIR_API = {
  URL: ENV.IS_PRODUCTION ? 'https://mimir.si.usi.ch/api' : 'http://localhost:8888',
};

const UI = {
  SPINNER_COLOR: '#283783',
};

const YEARS = {
  MIN: 1000,
  MAX: 9999,
};

export default {
  ENV,
  LOG_LEVEL: 'DEBUG', // one of 'DEBUG', 'INFO', or 'NONE'
  MIMIR_API,
  YEARS,
  UI,
};
