import React from 'react';
import { useSelector } from 'react-redux';
import { selectLoading } from './navbar/querySlice';
import LoadingOverlay from 'react-loading-overlay';
import Voronoi from './voronoi/Voronoi';
import Spinner from './spinner/Spinner';
import YearRange from './yearrange/YearRange';
import QueryResult from './queryResult/QueryResult';

export default function Main() {
  const loading = useSelector(selectLoading);

  return (
    <LoadingOverlay
      active={loading}
      spinner={<Spinner />}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(255, 255, 255, 0.7)',
          backdropFilter: 'blur(5px)',
        }),
      }}
    >
      <div
        className="vh-100 min-vh-100 flex-column d-flex justify-content-start"
        style={{ paddingTop: '61px' }}
      >
        <div className="flex-grow-0">
          <QueryResult />
        </div>
        <div className="flex-grow-0">
          <Voronoi width={1200} height={1200} padding={5} />
        </div>
        <div className="flex-grow-0">
          <YearRange />
        </div>
      </div>
    </LoadingOverlay>
  );
}
