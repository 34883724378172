import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import noresult from '../../result_not_found.png';
import { useDispatch, useSelector } from 'react-redux';
import { closeNoResultModal, selectNoResultModalOpen } from '../navbar/querySlice';
import Card from 'react-bootstrap/Card';

export default function NoResultsModal(props) {
  const dispatch = useDispatch();
  const noResultModalOpen = useSelector(selectNoResultModalOpen);

  // Function to close modal
  const handleClose = () => dispatch(closeNoResultModal());

  return (
    <Modal
      show={noResultModalOpen}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>No results found!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card.Text>
          Your query did not match any documents. There may be a number of reasons why your search
          did not return any results:
        </Card.Text>
        <Card.Text>
          <ul>
            <li>
              Spelling error or unknown search terms: please check the spelling or search for other
              terms
            </li>
            <li>
              The documents you are looking for have not yet been catalogued and recorded in the
              system
            </li>
          </ul>
        </Card.Text>
        <Card.Img variant="top" src={noresult} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
