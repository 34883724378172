import React from 'react';
import { connect } from 'react-redux';
import { setHoverSite, clearHoverSite, performGetEntity, selectHoverSite } from './voronoiSlice';
import { selectResult, selectResultsById } from '../navbar/querySlice';
import logger from '../../utils/logger';

import Entity from './entity/Entity';

import * as d3 from 'd3';
import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from './tooltip/Tooltip';
import { selectRange } from '../yearrange/yearRangeSlice';

// Actions that can be dispatched
const mapDispatch = { setHoverSite, clearHoverSite, performGetEntity };

// State to be linked to component props
const mapStateToProps = (state) => ({
  data: selectResult(state),
  hoveredSite: selectHoverSite(state),
  yearRange: selectRange(state),
  resultsById: selectResultsById(state),
});

class Voronoi extends React.Component {
  constructor(props) {
    logger.debug('> constructor');
    super(props);

    // Setup initial state
    this.state = {
      diagram: null,
      currentData: [],
      scales: null,
      colors: null,
    };

    // Bind this in methods
    this.createDiagram = this.createDiagram.bind(this);
    this.createPolygons = this.createPolygons.bind(this);
    this.bindInspectKey = this.bindInspectKey.bind(this);
    this.initializeColors = this.initializeColors.bind(this);
    this.createSites = this.createSites.bind(this);
    this.updateDiagram = this.updateDiagram.bind(this);
    this.updatePolygons = this.updatePolygons.bind(this);
    this.updateSites = this.updateSites.bind(this);
    this.goDown = this.goDown.bind(this);
    this.goDownAll = this.goDownAll.bind(this);
    this.goUp = this.goUp.bind(this);
    this.findChildrenOf = this.findChildrenOf.bind(this);
    this.setPolygonColor = this.setPolygonColor.bind(this);
    this.isEntityInYearRange = this.isEntityInYearRange.bind(this);
    this.updateDiagramHighlight = this.updateDiagramHighlight.bind(this);
  }

  /**
   * Component was mounted (e.g., website opened) so we need to create the diagram.
   */
  componentDidMount() {
    logger.debug('> componentDidMount');
    this.createDiagram();
  }

  /**
   * If Props updated (data), delete and recreate diagram.
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    logger.debug('> componentDidUpdate');
    const differentQuery = prevProps.data?.timestamp !== this.props.data?.timestamp;
    const differentYearsRange =
      prevProps.yearRange?.start !== this.props.yearRange?.start ||
      prevProps.yearRange?.end !== this.props.yearRange?.end;
    if (differentQuery) {
      this.createDiagram();
    } else if (differentYearsRange) {
      this.updateDiagramHighlight();
    }
  }

  bindInspectKey() {
    d3.select('body').on('keydown', () => {
      // If an entity is hovered and the user presses "i"
      if (this.props.hoveredSite && d3.event.keyCode === 73) {
        logger.debug('> performGetEntity');
        this.props.performGetEntity(this.props.hoveredSite);
      }
    });
  }

  /**
   * Initializes colors of all nodes in the visualization
   * by using the following color palette for root nodes:
   * https://coolors.co/633772-a21d4d-e00327-078c74-f2a007-7d6a4a-434f6b-08348c
   *
   * When going down the hierarchy, it uses brightened variations of root colors.
   **/
  initializeColors() {
    const { data: rootData } = this.props;
    const rootColors = {
      B: d3.hsl('#633772'),
      C: d3.hsl('#a21d4d'),
      D: d3.hsl('#078c74'),
      E: d3.hsl('#e00327'),
      J: d3.hsl('#08348c'),
      K: d3.hsl('#7d6a4a'),
      M: d3.hsl('#434f6b'),
      P: d3.hsl('#f2a007'),
    };
    let colors = {};
    (rootData?.nodes || []).forEach((node) => {
      if (node.level === 0) return;

      let fullNode = this.props.resultsById[node.name];
      let nestingLevel = node.level;
      let refCode = fullNode.refCode;
      let archiveLevel = refCode.charAt(0);

      let baseColor = rootColors[archiveLevel];

      let nodeColor =
        nestingLevel <= 1 ? baseColor : baseColor.brighter(0.2 * Math.min(nestingLevel, 10));

      colors[node.name] = nodeColor;
    });
    return colors;
  }

  /**
   * Create the voronoi diagram based on props data.
   */
  createDiagram() {
    logger.debug('> createDiagram');
    const { width, height, padding, data: rootData } = this.props;

    // Remove previous diagram
    select(this.voronoiSvg).selectAll('g').remove();

    const diagram = select(this.voronoiSvg).append('g');
    const currentData = rootData?.nodes?.filter((node) => node.level <= 1) || [];

    const xScale = scaleLinear().range([padding, width - padding]);
    const yScale = scaleLinear().range([height - padding, padding]);

    // FIXME: Need to adapt to data (i.e., x and y)
    const xMax = 1000;
    const yMax = 1000;
    xScale.domain([0, xMax]);
    yScale.domain([0, yMax]);

    const maxNesting = Math.max.apply(
      Math,
      rootData?.nodes?.map(function (o) {
        return o.level;
      }) || [0],
    );

    const strokeScale = scaleLinear().range([7, 2]).domain([0, maxNesting]);
    const scales = {
      xScale,
      yScale,
      strokeScale,
    };

    const colors = this.initializeColors();

    this.setState(
      {
        diagram,
        currentData,
        scales,
        colors,
      },
      () => {
        this.createPolygons();
        this.createSites();
        this.bindInspectKey();
      },
    );
  }

  /**
   * Create voronoi polygons in D3.
   */
  createPolygons() {
    logger.debug('> createPolygons');
    const { xScale, yScale, strokeScale } = this.state.scales;
    this.state.diagram
      .selectAll('polygon')
      .data(this.state.currentData, function (d) {
        return d.name;
      })
      .enter()
      .append('polygon')
      .attr('points', function (d) {
        return d.polygon.points
          .map(function (d) {
            return [xScale(d.x), yScale(d.y)].join(',');
          })
          .join(' ');
      })
      .attr('stroke', '#c1c1c1')
      .attr('stroke-width', function (d) {
        return strokeScale(d.level);
      })
      .attr('fill', this.setPolygonColor)
      // On mouseover, set node data in Redux state (to be shown in another component)
      .on('mouseover', (d) => {
        this.props.setHoverSite(d.name);
      })
      .on('mouseout', () => {
        this.props.clearHoverSite();
      })
      .on('click', this.goDown)
      .on('contextmenu', (d) => {
        d3.event.preventDefault();
        this.goUp(d);
      });
  }

  /**
   * Create voronoi sites in D3.
   */
  createSites() {
    logger.debug('> createSites');
    const { xScale, yScale } = this.state.scales;
    this.state.diagram
      .selectAll('circle')
      .data(this.state.currentData, function (d) {
        return d.name;
      })
      .enter()
      .append('circle')
      .attr('r', 3)
      .attr('fill', 'Coral')
      .attr('opacity', function (node) {
        return node.name === '$root' ? 0 : 1;
      })
      .attr('transform', function (node) {
        return 'translate(' + xScale(node.site.x) + ',' + yScale(node.site.y) + ')';
      })
      // On mouseover, set node data in Redux state (to be shown in another component)
      .on('mouseover', (d) => {
        this.props.setHoverSite(d.name);
      })
      .on('mouseout', () => {
        this.props.clearHoverSite();
      })
      .on('click', this.goDown)
      .on('contextmenu', (d) => {
        d3.event.preventDefault();
        this.goUp(d);
      });
  }

  goDownAll(d) {
    logger.debug('> goDownAll');
    const { data: rootData } = this.props;

    let allChildren = this.findChildrenOf([d], rootData.nodes);

    this.setState(
      {
        currentData: [...new Set(this.state.currentData.concat(allChildren))],
      },
      () => {
        this.updateDiagram(d);
      },
    );
  }

  goDown(d) {
    logger.debug('> goDown');
    const { data: rootData } = this.props;

    // If we press control, we expand all nodes recursively
    if (d3.event.altKey) {
      this.goDownAll(d);
      return;
    }

    // If we press shift, we open the SFA and not go down
    if (d3.event.shiftKey) {
      window.open('https://www.recherche.bar.admin.ch/recherche/link/en/archive/unit/' + d.name);
      return;
    }

    // Otherwise, we update the data with the children and update the treemap
    const currentData = this.state.currentData.concat(
      rootData.nodes.filter((node) => node.parent === d.name || d.name === node.name),
    );
    // Removing duplicates
    const currentDataNoDuplicates = [...new Set(currentData)];

    this.setState(
      {
        currentData: currentDataNoDuplicates,
      },
      () => {
        this.updateDiagram(d);
      },
    );
  }

  findChildrenOf(nodes, availableNodes) {
    let result = [];
    let toExplore = [...nodes];
    while (toExplore.length > 0) {
      let currentNode = toExplore.pop();
      let children = availableNodes.filter((node) => node.parent === currentNode.name);
      result.push(currentNode);
      result = result.concat(children);
      toExplore = toExplore.concat(children);
    }
    return result;
  }

  goUp(d) {
    logger.debug('> goUp');
    if (d.parent === '$root') return;

    const siblings = this.state.currentData.filter((node) => node.parent === d.parent);
    const childrenOfSiblings = this.findChildrenOf(siblings, this.state.currentData);

    const toRemove = siblings.concat(childrenOfSiblings);
    const uniqueToRemove = [...new Set(toRemove)];

    const currentData = this.state.currentData.filter((node) => uniqueToRemove.indexOf(node) < 0);
    this.setState(
      {
        currentData,
      },
      () => {
        this.updateDiagram(d);
      },
    );
  }

  /**
   * Update voronoi diagram.
   * This function is called when opening or closing a level of the diagram.
   * @param d
   */
  updateDiagram(d) {
    logger.debug('> updateDiagram');
    this.updatePolygons(d);
    this.updateSites(d);
  }

  /**
   * Update voronoi polygons in D3.
   * This function is called when opening or closing a level of the diagram.
   * @param d
   */
  updatePolygons(d) {
    logger.debug('> updatePolygons');
    const { xScale, yScale, strokeScale } = this.state.scales;
    const polygon = this.state.diagram
      .selectAll('polygon')
      .data(this.state.currentData, function (d) {
        return d.name;
      });

    polygon.exit().remove();

    polygon
      .enter()
      .append('polygon')
      .attr('points', function (d) {
        return d.polygon.points
          .map(function (d) {
            return [xScale(d.x), yScale(d.y)].join(',');
          })
          .join(' ');
      })
      .attr('stroke', '#c1c1c1')
      .attr('name', function (d) {
        return d.name;
      })
      .attr('stroke-width', function (d) {
        return strokeScale(d.level);
      })
      .style('opacity', 0)
      .attr('fill', this.setPolygonColor)
      // On mouseover, set node data in Redux state (to be shown in another component)
      .on('mouseover', (d) => {
        this.props.setHoverSite(d.name);
      })
      .on('mouseout', () => {
        this.props.clearHoverSite();
      })
      .on('click', this.goDown)
      .on('contextmenu', (d) => {
        d3.event.preventDefault();
        this.goUp(d);
      })
      .transition()
      .duration(600)
      .style('opacity', 1);
  }

  /**
   * Update voronoi sites in D3.
   * This function is called when opening or closing a level of the diagram.
   * @param d
   */
  updateSites(d) {
    var that = this;

    logger.debug('> updateSites');
    const { xScale, yScale } = this.state.scales;
    const { data: rootData } = this.props;
    const site = this.state.diagram.selectAll('circle').data(this.state.currentData, function (d) {
      return d.name;
    });

    site.attr('opacity', function (node) {
      let isRoot = node.name === '$root';
      let hasVisibleChildren = that.state.currentData.map((el) => el.parent).includes(node.name);
      return isRoot || hasVisibleChildren ? 0 : 1;
    });

    site
      .exit()
      .transition()
      .duration(400)
      .attr('transform', function (node) {
        const parent = rootData.nodes.find((n) => n.name === node.parent);
        return 'translate(' + xScale(parent.site.x) + ',' + yScale(parent.site.y) + ')';
      })
      .remove();

    site
      .enter()
      .append('circle')
      .attr('r', 3)
      .attr('fill', 'Coral')
      .attr('opacity', function (node) {
        let isRoot = node.name === '$root';
        let hasVisibleChildren = that.state.currentData.map((el) => el.parent).includes(node.name);
        return isRoot || hasVisibleChildren ? 0 : 1;
      })
      .attr('transform', function (node) {
        const parent = rootData.nodes.find((n) => n.name === node.parent);
        return 'translate(' + xScale(parent.site.x) + ',' + yScale(parent.site.y) + ')';
      })
      // On mouseover, set node data in Redux state (to be shown in another component)
      .on('mouseover', (d) => {
        this.props.setHoverSite(d.name);
      })
      .on('mouseout', () => {
        this.props.clearHoverSite();
      })
      .on('click', this.goDown)
      .on('contextmenu', (d) => {
        d3.event.preventDefault();
        this.goUp(d);
      })
      .transition()
      .duration(400)
      .attr('transform', function (node) {
        return 'translate(' + xScale(node.site.x) + ',' + yScale(node.site.y) + ')';
      });
  }

  updateDiagramHighlight() {
    this.state.diagram.selectAll('polygon').attr('fill', this.setPolygonColor);
  }

  /**
   * Given an entity, extract its colors and
   * @param d
   * @returns {string}
   */
  setPolygonColor(d) {
    const { colors } = this.state;

    if (!d.color) {
      return;
    }
    const isInRange = this.isEntityInYearRange(d);
    if (isInRange) {
      return colors[d.name];
    } else {
      return '#F8F9FA';
    }
  }

  /**
   * Whether an entity is in the selected year range.
   * At least one of the entity years must fall in the year range.
   * @returns {boolean}
   */
  isEntityInYearRange(e) {
    const {
      yearRange: { start, end },
      data: rootData,
      resultsById,
    } = this.props;

    const entity = resultsById[e.name];
    const allChildren = this.findChildrenOf([e], rootData.nodes);

    const entityYears = [
      ...new Set(
        entity?.years
          ? entity.years
          : allChildren.map((c) => resultsById[c.name]).flatMap((c) => c?.years || []),
      ),
    ];

    // If entity does not have years, it's not a document/dossier
    if (entityYears.length !== 0) {
      return entityYears.some((y) => y >= start && y <= end);
    }
    return true;
  }

  render() {
    const { width, height } = this.props;
    return (
      <Container>
        <Row>
          <Col xs="7">
            <svg
              ref={(node) => (this.voronoiSvg = node)}
              viewBox={`0 0 ${width} ${height}`}
              overflow={'visible'}
            />
            <Tooltip />
          </Col>
          <Col>
            <Entity />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatch)(Voronoi);
