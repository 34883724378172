import React, { useEffect, useState } from 'react';
import CNavbar from './components/navbar/CNavbar';
import './App.css';
import Main from './components/Main';
import SearchModal from './components/search/SearchModal';
import NoResultsModal from './components/queryResult/NoResultsModal';
import Mobile from './components/mobile/Mobile';
import AboutModal from './components/about/AboutModal';

const App = () => {
  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = () => {
    const mobileUserAgentRe = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    setIsMobile(mobileUserAgentRe.test(navigator.userAgent));
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile, { passive: true });
  });

  if (isMobile) {
    return (
      <div className="App">
        <Mobile />
      </div>
    );
  } else {
    return (
      <div className="App vh-100 min-vh-100">
        <CNavbar />
        <Main />
        <SearchModal />
        <NoResultsModal />
        <AboutModal />
      </div>
    );
  }
};

export default App;
